<template>
    <div class="space client-content-padding">
        <SpaceCourse
            v-if="dataLoaded"
            :title="course.title"
            :description="course.excerpt"
            :thumbnail="course.thumbnail"
            :tags="course.tags"
            :button-label="labels.start_course"
            :button-action="() => startCourse(course._id)"
        />
        <div class="space-navigation">
            <router-link
                v-for="(link, index) in navigationLinks"
                :to="{name: link.name, params: link.params}"
                :class="{link: true, active: $route.name == link.name ? true : false, ['no-focus-outline']: true}"
                :key="`space-nav-${index}`"
            >
                <span>{{ link.label }}</span>
                <div v-if="index + 1 != navigationLinks.length" class="line"></div>
            </router-link> 
        </div>
        <SvgLoader v-if="!dataLoaded" :loading="true" />
        <div v-if="dataLoaded" class="space-content">
            <router-view name="space_mbundle" :data-loaded="dataLoaded" :mbundle="course"></router-view>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: 'SpaceSingleMonetizationBundle',
    components: {
        SvgLoader: () => import('@/components/Preloaders/SvgLoader'),
        SpaceCourse: () => import('@/components/Cards/SpaceCourse.vue')
    },
    data() {
        return {
            labels: {
                register: 'Register',
                home: 'Home',
                course: 'Course',
                details: 'Details',
                authors: 'Authors',
                start_course: 'Start course'
            },
            course: {},
//             course: {
//                 title: 'Lorem ipsum',
//                 excerpt: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ut mauris vitae erat consectetur mattis ac ac odio. Aliquam ut tristique orci.',
//                 description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ut mauris vitae erat consectetur mattis ac ac odio. Aliquam ut tristique orci. Maecenas dignissim auctor sapien, et vestibulum neque viverra at. Fusce sed orci odio. Maecenas dignissim sagittis efficitur. Mauris ullamcorper nibh quis condimentum tincidunt. Vestibulum tincidunt mauris purus, auctor vulputate mi lacinia at. Fusce libero ligula, varius nec sapien a, tristique cursus turpis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;

// Vestibulum gravida tincidunt iaculis. Nullam et elit et dui pellentesque volutpat sit amet at arcu. Maecenas vel ultrices libero. Nam tincidunt diam sed ipsum pellentesque, sollicitudin faucibus nisl auctor. Nullam imperdiet odio nec libero elementum, non scelerisque nibh iaculis. Suspendisse in consectetur purus. Donec eget ullamcorper odio. Fusce congue orci in tellus blandit dignissim.

// Ut vitae ante laoreet justo lobortis placerat. Quisque aliquam mauris augue. Praesent imperdiet mattis lectus. Mauris sit amet rhoncus enim, nec malesuada ipsum. Curabitur tempus ipsum non dui eleifend, vel sagittis lectus iaculis. Sed consectetur ante eu nisi vulputate blandit. In sodales tincidunt libero quis feugiat. Donec sapien metus, suscipit eu aliquet et, placerat eu purus. Nullam pharetra cursus egestas. Ut commodo libero in sapien ullamcorper, semper bibendum felis vulputate. Sed ut consequat nisl. Phasellus vulputate dolor imperdiet tellus ultrices hendrerit.`,
//                 thumbnail: 'https://www.autocentre.ua/wp-content/uploads/2020/08/mitsubishi-evo-ix-1-1.jpg',
//                 tags: [
//                     {
//                         slug: 'tag-1',
//                         title: 'Tag 1'
//                     },
//                     {
//                         slug: 'tag-2',
//                         title: 'Tag 2'
//                     },
//                     {
//                         slug: 'tag-3',
//                         title: 'Tag 3'
//                     },
//                     {
//                         slug: 'tag-4',
//                         title: 'Tag 4'
//                     },
//                     {
//                         slug: 'tag-5',
//                         title: 'Tag 5'
//                     }
//                 ],
//                 authors: [
//                     {
//                         name: 'Author Author',
//                         avatar: 'https://clipart-library.com/images_k/black-man-transparent/black-man-transparent-8.png',
//                         role: 'Lead manager',
//                         description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ut mauris vitae erat consectetur mattis ac ac odio. Aliquam ut tristique orci. Maecenas dignissim auctor sapien, et vestibulum neque viverra at. Fusce sed orci odio. Maecenas dignissim sagittis efficitur. Mauris ullamcorper nibh quis condimentum tincidunt. Vestibulum tincidunt mauris purus, auctor vulputate mi lacinia at. Fusce libero ligula, varius nec sapien a, tristique cursus turpis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;

//                         Vestibulum gravida tincidunt iaculis. Nullam et elit et dui pellentesque volutpat sit amet at arcu. Maecenas vel ultrices libero. Nam tincidunt diam sed ipsum pellentesque, sollicitudin faucibus nisl auctor. Nullam imperdiet odio nec libero elementum, non scelerisque nibh iaculis. Suspendisse in consectetur purus. Donec eget ullamcorper odio. Fusce congue orci in tellus blandit dignissim.
                        
//                         Ut vitae ante laoreet justo lobortis placerat. Quisque aliquam mauris augue. Praesent imperdiet mattis lectus. Mauris sit amet rhoncus enim, nec malesuada ipsum. Curabitur tempus ipsum non dui eleifend, vel sagittis lectus iaculis. Sed consectetur ante eu nisi vulputate blandit. In sodales tincidunt libero quis feugiat. Donec sapien metus, suscipit eu aliquet et, placerat eu purus. Nullam pharetra cursus egestas. Ut commodo libero in sapien ullamcorper, semper bibendum felis vulputate. Sed ut consequat nisl. Phasellus vulputate dolor imperdiet tellus ultrices hendrerit.`,
//                         primaryAuthor: true
//                     },
//                     {
//                         name: 'Author Author',
//                         avatar: 'https://clipart-library.com/images_k/black-man-transparent/black-man-transparent-8.png',
//                         role: 'Lead manager',
//                         description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ut mauris vitae erat consectetur mattis ac ac odio. Aliquam ut tristique orci. Maecenas dignissim auctor sapien, et vestibulum neque viverra at. Fusce sed orci odio. Maecenas dignissim sagittis efficitur. Mauris ullamcorper nibh quis condimentum tincidunt. Vestibulum tincidunt mauris purus, auctor vulputate mi lacinia at. Fusce libero ligula, varius nec sapien a, tristique cursus turpis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;

//                         Vestibulum gravida tincidunt iaculis. Nullam et elit et dui pellentesque volutpat sit amet at arcu. Maecenas vel ultrices libero. Nam tincidunt diam sed ipsum pellentesque, sollicitudin faucibus nisl auctor. Nullam imperdiet odio nec libero elementum, non scelerisque nibh iaculis. Suspendisse in consectetur purus. Donec eget ullamcorper odio. Fusce congue orci in tellus blandit dignissim.
                        
//                         Ut vitae ante laoreet justo lobortis placerat. Quisque aliquam mauris augue. Praesent imperdiet mattis lectus. Mauris sit amet rhoncus enim, nec malesuada ipsum. Curabitur tempus ipsum non dui eleifend, vel sagittis lectus iaculis. Sed consectetur ante eu nisi vulputate blandit. In sodales tincidunt libero quis feugiat. Donec sapien metus, suscipit eu aliquet et, placerat eu purus. Nullam pharetra cursus egestas. Ut commodo libero in sapien ullamcorper, semper bibendum felis vulputate. Sed ut consequat nisl. Phasellus vulputate dolor imperdiet tellus ultrices hendrerit.`,
//                         primaryAuthor: true
//                     },
//                     {
//                         name: 'Author Author',
//                         avatar: 'https://clipart-library.com/images_k/black-man-transparent/black-man-transparent-8.png',
//                         role: 'Lead manager',
//                         description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ut mauris vitae erat consectetur mattis ac ac odio. Aliquam ut tristique orci. Maecenas dignissim auctor sapien, et vestibulum neque viverra at. Fusce sed orci odio. Maecenas dignissim sagittis efficitur. Mauris ullamcorper nibh quis condimentum tincidunt. Vestibulum tincidunt mauris purus, auctor vulputate mi lacinia at. Fusce libero ligula, varius nec sapien a, tristique cursus turpis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;

//                         Vestibulum gravida tincidunt iaculis. Nullam et elit et dui pellentesque volutpat sit amet at arcu. Maecenas vel ultrices libero. Nam tincidunt diam sed ipsum pellentesque, sollicitudin faucibus nisl auctor. Nullam imperdiet odio nec libero elementum, non scelerisque nibh iaculis. Suspendisse in consectetur purus. Donec eget ullamcorper odio. Fusce congue orci in tellus blandit dignissim.
                        
//                         Ut vitae ante laoreet justo lobortis placerat. Quisque aliquam mauris augue. Praesent imperdiet mattis lectus. Mauris sit amet rhoncus enim, nec malesuada ipsum. Curabitur tempus ipsum non dui eleifend, vel sagittis lectus iaculis. Sed consectetur ante eu nisi vulputate blandit. In sodales tincidunt libero quis feugiat. Donec sapien metus, suscipit eu aliquet et, placerat eu purus. Nullam pharetra cursus egestas. Ut commodo libero in sapien ullamcorper, semper bibendum felis vulputate. Sed ut consequat nisl. Phasellus vulputate dolor imperdiet tellus ultrices hendrerit.`
//                     },
//                     {
//                         name: 'Author Author',
//                         avatar: 'https://clipart-library.com/images_k/black-man-transparent/black-man-transparent-8.png',
//                         role: 'Lead manager',
//                         description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ut mauris vitae erat consectetur mattis ac ac odio. Aliquam ut tristique orci. Maecenas dignissim auctor sapien, et vestibulum neque viverra at. Fusce sed orci odio. Maecenas dignissim sagittis efficitur. Mauris ullamcorper nibh quis condimentum tincidunt. Vestibulum tincidunt mauris purus, auctor vulputate mi lacinia at. Fusce libero ligula, varius nec sapien a, tristique cursus turpis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;

//                         Vestibulum gravida tincidunt iaculis. Nullam et elit et dui pellentesque volutpat sit amet at arcu. Maecenas vel ultrices libero. Nam tincidunt diam sed ipsum pellentesque, sollicitudin faucibus nisl auctor. Nullam imperdiet odio nec libero elementum, non scelerisque nibh iaculis. Suspendisse in consectetur purus. Donec eget ullamcorper odio. Fusce congue orci in tellus blandit dignissim.
                        
//                         Ut vitae ante laoreet justo lobortis placerat. Quisque aliquam mauris augue. Praesent imperdiet mattis lectus. Mauris sit amet rhoncus enim, nec malesuada ipsum. Curabitur tempus ipsum non dui eleifend, vel sagittis lectus iaculis. Sed consectetur ante eu nisi vulputate blandit. In sodales tincidunt libero quis feugiat. Donec sapien metus, suscipit eu aliquet et, placerat eu purus. Nullam pharetra cursus egestas. Ut commodo libero in sapien ullamcorper, semper bibendum felis vulputate. Sed ut consequat nisl. Phasellus vulputate dolor imperdiet tellus ultrices hendrerit.`
//                     },
//                     {
//                         name: 'Author Author',
//                         avatar: 'https://clipart-library.com/images_k/black-man-transparent/black-man-transparent-8.png',
//                         role: 'Lead manager',
//                         description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ut mauris vitae erat consectetur mattis ac ac odio. Aliquam ut tristique orci. Maecenas dignissim auctor sapien, et vestibulum neque viverra at. Fusce sed orci odio. Maecenas dignissim sagittis efficitur. Mauris ullamcorper nibh quis condimentum tincidunt. Vestibulum tincidunt mauris purus, auctor vulputate mi lacinia at. Fusce libero ligula, varius nec sapien a, tristique cursus turpis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;

//                         Vestibulum gravida tincidunt iaculis. Nullam et elit et dui pellentesque volutpat sit amet at arcu. Maecenas vel ultrices libero. Nam tincidunt diam sed ipsum pellentesque, sollicitudin faucibus nisl auctor. Nullam imperdiet odio nec libero elementum, non scelerisque nibh iaculis. Suspendisse in consectetur purus. Donec eget ullamcorper odio. Fusce congue orci in tellus blandit dignissim.
                        
//                         Ut vitae ante laoreet justo lobortis placerat. Quisque aliquam mauris augue. Praesent imperdiet mattis lectus. Mauris sit amet rhoncus enim, nec malesuada ipsum. Curabitur tempus ipsum non dui eleifend, vel sagittis lectus iaculis. Sed consectetur ante eu nisi vulputate blandit. In sodales tincidunt libero quis feugiat. Donec sapien metus, suscipit eu aliquet et, placerat eu purus. Nullam pharetra cursus egestas. Ut commodo libero in sapien ullamcorper, semper bibendum felis vulputate. Sed ut consequat nisl. Phasellus vulputate dolor imperdiet tellus ultrices hendrerit.`
//                     }
//                 ],
//                 _id: 1,
//                 targetAudience: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ut mauris vitae erat consectetur mattis ac ac odio. Aliquam ut tristique orci. Maecenas dignissim auctor sapien, et vestibulum neque viverra at. Fusce sed orci odio. Maecenas dignissim sagittis efficitur. Mauris ullamcorper nibh quis condimentum tincidunt. Vestibulum tincidunt mauris purus, auctor vulputate mi lacinia at. Fusce libero ligula, varius nec sapien a, tristique cursus turpis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;

// Vestibulum gravida tincidunt iaculis. Nullam et elit et dui pellentesque volutpat sit amet at arcu. Maecenas vel ultrices libero. Nam tincidunt diam sed ipsum pellentesque, sollicitudin faucibus nisl auctor. Nullam imperdiet odio nec libero elementum, non scelerisque nibh iaculis. Suspendisse in consectetur purus. Donec eget ullamcorper odio. Fusce congue orci in tellus blandit dignissim.

// Ut vitae ante laoreet justo lobortis placerat. Quisque aliquam mauris augue. Praesent imperdiet mattis lectus. Mauris sit amet rhoncus enim, nec malesuada ipsum. Curabitur tempus ipsum non dui eleifend, vel sagittis lectus iaculis. Sed consectetur ante eu nisi vulputate blandit. In sodales tincidunt libero quis feugiat. Donec sapien metus, suscipit eu aliquet et, placerat eu purus. Nullam pharetra cursus egestas. Ut commodo libero in sapien ullamcorper, semper bibendum felis vulputate. Sed ut consequat nisl. Phasellus vulputate dolor imperdiet tellus ultrices hendrerit.`,
//                 promises: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ut mauris vitae erat consectetur mattis ac ac odio. Aliquam ut tristique orci. Maecenas dignissim auctor sapien, et vestibulum neque viverra at. Fusce sed orci odio. Maecenas dignissim sagittis efficitur. Mauris ullamcorper nibh quis condimentum tincidunt. Vestibulum tincidunt mauris purus, auctor vulputate mi lacinia at. Fusce libero ligula, varius nec sapien a, tristique cursus turpis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;

// Vestibulum gravida tincidunt iaculis. Nullam et elit et dui pellentesque volutpat sit amet at arcu. Maecenas vel ultrices libero. Nam tincidunt diam sed ipsum pellentesque, sollicitudin faucibus nisl auctor. Nullam imperdiet odio nec libero elementum, non scelerisque nibh iaculis. Suspendisse in consectetur purus. Donec eget ullamcorper odio. Fusce congue orci in tellus blandit dignissim.

// Ut vitae ante laoreet justo lobortis placerat. Quisque aliquam mauris augue. Praesent imperdiet mattis lectus. Mauris sit amet rhoncus enim, nec malesuada ipsum. Curabitur tempus ipsum non dui eleifend, vel sagittis lectus iaculis. Sed consectetur ante eu nisi vulputate blandit. In sodales tincidunt libero quis feugiat. Donec sapien metus, suscipit eu aliquet et, placerat eu purus. Nullam pharetra cursus egestas. Ut commodo libero in sapien ullamcorper, semper bibendum felis vulputate. Sed ut consequat nisl. Phasellus vulputate dolor imperdiet tellus ultrices hendrerit.`,
//                 providers: [
//                     {
//                         image: 'https://www.autocentre.ua/wp-content/uploads/2020/08/mitsubishi-evo-ix-1-1.jpg',
//                         description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ut mauris vitae erat consectetur mattis ac ac odio. Aliquam ut tristique orci. Maecenas dignissim auctor sapien, et vestibulum neque viverra at. Fusce sed orci odio. Maecenas dignissim sagittis efficitur. Mauris ullamcorper nibh quis condimentum tincidunt.'
//                     },
//                     {
//                         image: 'https://www.autocentre.ua/wp-content/uploads/2020/08/mitsubishi-evo-ix-1-1.jpg',
//                         description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ut mauris vitae erat consectetur mattis ac ac odio. Aliquam ut tristique orci. Maecenas dignissim auctor sapien, et vestibulum neque viverra at. Fusce sed orci odio. Maecenas dignissim sagittis efficitur. Mauris ullamcorper nibh quis condimentum tincidunt.'
//                     },
//                     {
//                         image: 'https://www.autocentre.ua/wp-content/uploads/2020/08/mitsubishi-evo-ix-1-1.jpg',
//                         description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ut mauris vitae erat consectetur mattis ac ac odio. Aliquam ut tristique orci. Maecenas dignissim auctor sapien, et vestibulum neque viverra at. Fusce sed orci odio. Maecenas dignissim sagittis efficitur. Mauris ullamcorper nibh quis condimentum tincidunt.'
//                     },
//                     {
//                         image: 'https://www.autocentre.ua/wp-content/uploads/2020/08/mitsubishi-evo-ix-1-1.jpg',
//                         description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ut mauris vitae erat consectetur mattis ac ac odio. Aliquam ut tristique orci. Maecenas dignissim auctor sapien, et vestibulum neque viverra at. Fusce sed orci odio. Maecenas dignissim sagittis efficitur. Mauris ullamcorper nibh quis condimentum tincidunt.'
//                     },
//                     {
//                         image: 'https://www.autocentre.ua/wp-content/uploads/2020/08/mitsubishi-evo-ix-1-1.jpg',
//                         description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ut mauris vitae erat consectetur mattis ac ac odio. Aliquam ut tristique orci. Maecenas dignissim auctor sapien, et vestibulum neque viverra at. Fusce sed orci odio. Maecenas dignissim sagittis efficitur. Mauris ullamcorper nibh quis condimentum tincidunt.'
//                     }
//                 ]
//             },
            dataLoaded: false
        }
    },
    methods: {
        ...mapActions('space', ['getAccountSpaceMbundle']),
        ...mapActions('notification', ['addNotification']),
        getSpaceMbundle() {
            this.dataLoaded = false;

            this.getAccountSpaceMbundle(this.$route.params.bundleId).then( res => {
                if (res.success) {
                    console.log('Got res:', res);
                    this.course = res.data;
                }

                if (res.error) {
                    this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels })
                }

                this.dataLoaded = true;
            } ).catch( err => console.log( err ) );
        },
        startCourse(id) {
            console.log(`Start course ${id}`);
            this.$router.push({
                name: 'bundle',
                params: {
                    monetization_bundle_id: id
                }
            })
        }
    },
    mounted() {
        this.getSpaceMbundle();
    },
    computed: {
        navigationLinks() {
            console.log(this.$route.params)
            return [
                {
                    name: 'account-space-mbundle',
                    params: {
                        mbundle: this.$route.params.bundleId
                    },
                    label: this.labels.home
                },
                {
                    name: 'account-space-mbundle-course',
                    params: {
                        mbundle: this.$route.params.bundleId
                    },
                    label: this.labels.course
                },
                {
                    name: 'account-space-mbundle-details',
                    params: {
                        mbundle: this.$route.params.bundleId
                    },
                    label: this.labels.details
                },
                {
                    name: 'account-space-mbundle-authors',
                    params: {
                        mbundle: this.$route.params.bundleId
                    },
                    label: this.labels.authors
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/common";

.space {
    &-navigation {
        display: flex;
        width: 100%;
        background-color: #fff;
        box-shadow: $box_shadow;
        margin: 2rem 0;
        padding: 1rem;
        justify-content: center;

        a {
            display: flex;

            span {
                transition-property: color, background-color;
                transition-duration: 300ms;
                transition-timing-function: linear;
                padding: 5px 15px;
                border-radius: 5px;
                background-color: rgba(0,0,0,0.03);
                color: $focus_color;
            }

            div.line {
                width: 1px;
                height: 100%;
                background-color:rgba(0,0,0,0.1);
                margin-left: 15px;
                margin-right: 15px;
            }

            &:last-child {
                border-right: 0px;
            }

            &.active,
            &:hover {
                text-decoration: none;

                span {
                    background-color: $focus_color;
                    color: $color_over_focus;
                    text-decoration: none;
                }
            }
        }
    }

    &-content {
        @include client-margin;
    }
}
</style>